// 新着ブログ記事リスト
.blog-article__pickup {
  ul {
    @extend %grid-col-4a;
    list-style: none;
  }
  li {
    // transition: 0.2s ease-out;
    line-height: 1.4;
    a {
      position: relative;
      display: block;
    }
  }
  .img {
    overflow: hidden;
    margin-bottom: 7px;
    border: 1px solid #e5e5e5;
    img {
      display: block;
      aspect-ratio: 4 / 3;
      width: 100%;
      height: auto;
      object-fit: cover;
      transition: 0.2s ease-out;
      @include mq(md) {
      }
    }
  }
  .date {
    font-size: 12px;
    color: #a8a8a8;
    // margin-left: -10px;
    margin-bottom: 5px;
    @include mq(md) {
      font-size: 12px;
      margin-left: -7px;
    }
  }
  .txt {
    font-size: 14px;
  }
  a:hover {
    .img {
      img {
        transform: scale(1.05);
        transition: 0.2s ease-out;
        -webkit-transition: 0.2s ease-out;
      }
    }
    .txt {
      font-size: 14px;
      color: $key_color_01;
      transition: 0.2s ease-out;
      -webkit-transition: 0.2s ease-out;
    }
  }
  .new {
    top: -10px;
    right: 0px;
    @include mq(md) {
      // right: -5px;
    }
    @include mq(sm) {
      right: 0;
    }
  }
  &__link {
    margin-top: 30px;
    @include mq(md) {
      margin-top: 40px / 705px * 100%;
    }
  }
}
