.carousel__gallery {
  > img {
    display: block;
    width: 100%;
  }
  > ul {
    display: flex;
    flex-wrap: wrap;
    margin: percentage(10px / 500px) 0 0;
    gap: 10px 2%;
  }
  li {
    $size_01: percentage(92px / 500px);
    flex: 0 1 $size_01;
    position: relative;
    display: block;
    overflow: hidden;
    // margin: percentage(10px / 500px) percentage(5px / 500px) 0;
    // height: 0;
    // padding: $size_01 0 0;
    // &:nth-child(-n + 5) {
    //   margin-top: 0;
    // }
    // &:nth-child(5n + 1) {
    //   margin-left: 0;
    // }
    // &:nth-child(5n) {
    //   margin-right: 0;
    // }
  }
  a {
    // position: absolute;
    // top: -200%;
    // left: -200%;
    // width: 500%;
    // height: 500%;
    display: block;
    img {
      display: block;
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      // position: absolute;
      // top: 0;
      // right: 0;
      // bottom: 0;
      // left: 0;
      // height: 20%;
      // margin: auto;
    }
  }
}
