@charset "UTF-8";

//各種パラメータ
@import 'param';

//プロジェクトパラメータ
@import 'object/project/project_variables';

//ミックスイン
@import 'mixin';


// 汎用変数, 関数
@import "object/utility/_variables.scss";
// コンポーネント
@import "object/frame/_common.scss";
@import "object/frame/_extend.scss";
@import "object/frame/_main-contents.scss";
@import "object/frame/_variables.scss";


//ヘッダー
@import 'parts_header';

//フッター
@import 'parts_footer';



// コンポーネント
@import "object/component/_business-category.scss";
@import "object/component/_button.scss";
@import "object/component/_carousel.scss";
@import "object/component/_company-outline.scss";
@import "object/component/_contact-form.scss";
@import "object/component/_contact-information.scss";
@import "object/component/_cover-art.scss";
@import "object/component/_drawer.scss";
@import "object/component/_feature-list.scss";
@import "object/component/_for-development.scss";
@import "object/component/_gallery.scss";
@import "object/component/_googlemap.scss";
@import "object/component/_heading.scss";
@import "object/component/_history.scss";
@import "object/component/_mainvisual.scss";
@import "object/component/_message.scss";
@import "object/component/_operation-flow.scss";
@import "object/component/_ornament-figure.scss";
@import "object/component/_pagination.scss";
@import "object/component/_portfolio.scss";
@import "object/component/_section.scss";
@import "object/component/_separate-column.scss";
@import "object/component/_tab-navigation.scss";
@import "object/component/blog/_article__detail.scss";
@import "object/component/blog/_article__index.scss";
@import "object/component/blog/_article__nav.scss";
@import "object/component/blog/_article__pickup.scss";
@import "object/component/blog/_container.scss";
// プロジェクト独自スタイル
@import 'object/project/project_styles';
