.section {
  &.__strong {
    background-image: url($compo-image+'section/polygon_bg.png');
  }
}

.section__header h2 {
  font-style: normal;
}

.top_header #copy,
.top_header #copy .sub-ttl {
  color: $key_color_02;
}

// ヘッダーロゴ
.top_header {
  header {
    &.pc {
      &.fixed {
        h1 img {
          width: 80px;
        }
      }
      &:not(.fixed) {
        h1 img {
          width: 140px;
        }
      }
    }
    &.sp {
      h1 img {
        max-width: 100px!important;
        // width: 80px;
      }
    }
  }
}
#second_header nav h1 img {
  @include mq-min {
    width: 80px;
  }
}
.globalMenuSp .sp-nav-logo {
  img {
    max-width: 100px;
  }
}

#top_footer .left dt img {
  width: 200px;
  @include mq {
    width: 120px;
  }
}


.__mv-included + .main-contents {
  margin-top: 0;
  padding-top: 0;
  // section:first-child {
  //   padding-top: 90px;
  //   @include mq {
  //     padding-top: 0;
  //   }
  // }
}




.works_slider {
  
  &_wrap {
    @extend %margin-component;
    position: relative;

    .slick-prev:before, .slick-next:before {
      color: $key_color_01;
    }

    .slick-prev {
      left: -25px;
      @include mq {
        left: -10px;
      }
    }
    .slick-next {
      @include mq {
        right: -10px;
      }
    }
  }

  li {
    padding: 10px;
  }
  dt {
    aspect-ratio: 4/3;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  dd {
    margin-top: 10px;
  }
  
}


.photo-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  > * {
    margin: 0 0 2%;
    width: calc((100% - 4%) / 3);
    @include mq {
      width: calc((100% - 2%) / 2);
    }
    @include mq(sm) {
      margin: 0 0 3%;
      width: 100%;
    }
  }
  figure {
    img {
      width: 100%;
    }
  }
  
  &.--company {
    @include mq {
      > :nth-child(3) {
        display: none;
      }
      
    }
    @include mq(sm) {
      > :nth-child(2) {
        display: none;
      }
    }
  }
}


.portfolio__list {
  div {
    aspect-ratio: 6 / 4;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// .top_header #copy {
//   color: #fff;
//   .ttl, .sub-ttl {
//     color: inherit;
//   }
// }

// Gnaviのカレントとラインの色
// .top_header nav > ul li {
//   &.current-menu-item {
//     color: #D7D7D8;
//     font-weight: bold;
//     a {
//       color: inherit;
//       font-family: inherit;
//     }
//   }
// }
// .top_header #slide-line {
//   background-color: #D7D7D8!important;
// }

// 
// .cover-art  {
//   &__container {
//     background-image: url(/wp/wp-content/uploads/2020/04/5259c2ffa902c17fbb4f76a373b2ff4f.jpg);
//   }
//   h1 {
//     background-color: #B5B5B8;
//     font-style: normal;
//     span {
//       font-style: oblique;
//     }
//   }
// }
// .section__header h2 {
//   font-style: normal;
//   span {
//     font-style: oblique;
//   }
// }

// .history dt {
//   font-style: normal;
// }

// .business-category__column {
//   li {
//     background-color: #EEEEEE;
//     border: 1px solid #707070;
//     color: #000;
//   }
//   p strong span {
//     background-color: #FFDF00;
//     color: #000;
//     font-size: 14px;
//   }
// }

// .heading__level-2, .portfolio-detail h2, .separate-column__02 h2 {
//   font-style: normal;
// }


// .blog-article__nav li ins {
//   color: inherit;
// }
// .new {
//   background-color: #D7D7D8;
//   color: #000;
// }