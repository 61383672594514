%business-category__row__prefix {
  flex: 0 0 auto;
  display: block;
  margin: 5px 15px 0 0;
  border-radius: 50px;
  padding: 3px 15px 3px 20px;
  background-color: $key_color_01;
  color: $text_color_02;
  letter-spacing: 1px;
  line-height: 1.8;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-style: italic;
  font-family: $font_family_03;
  @include mq(lg) {
    display: inline-block;
    margin: 0 0 5px;
  }
  @include mq(md) {
    position: absolute;
    top: -8px;
    left: 50%;
    margin: 0;
    padding: 7px 20px;
    transform: translateX(-50%);
  }
}
.business-category__row {
  li {
    background: #eeeeee;
    margin: 20px 0 0;
    @include mq(md) {
      margin-top: 30px;
    }
    &:first-child {
      margin-top: 0;
    }
    > a {
      display: block;
      .business-category__row__container {
        position: relative;
        @include mq {
          padding-bottom: 50px;
        }
        @include mq-min {
          dl {
            padding-right: 20px;
          }
        }
        &::after {
          content: '';
          position: absolute;
          display: block;
          border-bottom: 1px solid #000;
          border-right: 1px solid #000;
          @include mq {
            bottom: 15px;
            right: calc(50% - 7.5px);
            width: 15px;
            height: 15px;
            transform: rotate(45deg);
          }
          @include mq-min {
            right: 20px;
            top: calc(50% - 10px);
            width: 20px;
            height: 20px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  &__container {
    @extend %section__container;
    display: flex;
    align-items: center;
    @include mq {
      position: relative;
      display: block;
      padding: 0 0 30px;
    }
  }
  .__figure {
    flex: 0 1 percentage(280px / 1000px);
    margin: 0 percentage(15px / 1000px);
    img {
      display: block;
      width: 100%;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    @include mq {
      margin: 0;
      padding: 10px 10px 0;
    }
  }
  dl {
    flex: 0 1 percentage((1000px - 280px - 30px) / 1000px);
    display: inline-block;
    margin: 0 percentage(15px / 1000px);
    padding: 25px 0 20px;
    @include mq {
      margin: 15px 0 0;
      padding: 0 10px;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }
  dt {
    display: flex;
    align-items: flex-start;
    margin: 0;
    @include mq(lg) {
      display: block;
    }
    span {
      flex: 1 1 auto;
      display: block;
      color: $key_color_01;
      line-height: 1.8;
      font-weight: normal;
      font-size: 24px;
      @include mq(lg) {
        display: block;
      }
      @include mq(md) {
        margin-top: 15px;
        margin-bottom: 10px;
        line-height: 1.4;
      }
    }
    ins {
      @extend %business-category__row__prefix;
    }

    &:empty {
      display: none;
    }
  }
  dd {
    @extend .paragraph__small;
    margin: 15px 0 0;

    a {
      text-decoration: underline;
      transition: 0.3s ease-in-out;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  & + p {
    max-width: 410px;
    margin: 60px auto 0;
    @include mq {
      max-width: none;
      width: 95%;
      margin-top: 20px;
    }
  }
  &.__has-counter {
    counter-reset: operation-flow;
    li {
      counter-increment: operation-flow;
      dt {
        &::before {
          @extend %business-category__row__prefix;
        }
      }
      &:nth-child(-n + 10) {
        dt::before {
          content: 'FLOW 0' counter(operation-flow);
        }
      }
      &:nth-child(n + 10) {
        dt::before {
          content: 'FLOW ' counter(operation-flow);
        }
      }
    }
  }
}

.business-category__column {
  list-style: none;
  margin: 0;
  padding: 0;
  @include mq(lg) {
    flex-wrap: wrap;
  }
  @include mq {
    display: block;
  }

  &.--col-4 {
    @extend %grid-col-4a;
  }
  &.--col-3 {
    @extend %grid-col-3a;
  }
  li {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    flex-direction: column;
    padding: 0 0 20px;
    background-color: $key_color_01;
    color: $key_color_02;
  }
  .__figure,
  p {
    flex: 0 1 auto;
  }
  .__figure {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
  }
  img {
    display: block;
    width: 100%;
  }
  p,
  div {
    padding: 0 20px;
  }
  p {
    @extend .paragraph__xx-small;
    margin: 15px 0 0;
    strong {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: block;
      margin: auto;
      text-align: center;
      span {
        position: relative;
        display: inline-block;
        min-width: 110px;
        padding: 5px 0.5em;
        background-color: $key_color_03;
        color: $text_color_02;
        line-height: 1.15;
        font-size: 16px;
        font-weight: normal;
        transform: translateY(-50%);
        @include mq(md) {
          padding: 5px 1em;
        }
      }
    }
  }
  div {
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end;
    margin: 20px 0 0;
  }
  .button {
    width: 100%;
    height: 40px;
    border-color: $key_color_02;
    letter-spacing: 0;
    // line-height: 38px;
    font-size: 14px;
    font-style: normal;
    &::before ,
    &::after {
      @include rv_vw(15);
    }
    &:hover {
      &::before,
      &::after {
        @include rv_vw(10);
      }
    }
  }
}
