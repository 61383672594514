%dev-content {
  position: relative;
  padding: 40px;
  &::before,
  &::after {
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    margin: 20px;
    border: 1px solid #fff;
    content: '';
  }
  &::before {
    top: 0;
    left: 0;
    border-width: 1px 0 0 1px;
  }
  &::after {
    right: 0;
    bottom: 0;
    border-width: 0 1px 1px 0;
  }
}

ins.dummy-content {
  @extend %dev-content;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  margin: 0 auto;
  background: rgba(#000, 0.25);
  color: #fff;
  text-decoration: none;
  font-size: 24px;
}

.development-comment {
  @extend %dev-content;
  margin: 60px 0 0;
  background: rgba(#000, 0.7);
  color: #fff;
  ol {
    list-style: decimal;
    padding-left: 1.5em;
    line-height: 1.8;
  }
  li {
    margin-top: 7.5px;
    &:first-child {
      margin-top: 0;
    }
  }
}

.dev-compo-separater {
  height: 0;
  margin: 100px 0;
  border: 2px dashed #bbb;
  border-width: 2px 0 0;
}

.spacer {
  &--between-section {
    height: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content:center;
    &::before {
      content: "spacer";
    }
  }
}