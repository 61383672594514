@charset "UTF-8";

/* ---------------------------------------------------------
    
--------------------------------------------------------- */
/* フッター
================================================== */
#top_footer {
  position: relative;
  .inner {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: stretch;
    -webkit-align-items: stretch;
    // @include wt_vw(1000);
    max-width: 1020px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 0 10px;
    @include mq(lg) {
      flex-direction: column;
      -webkit-flex-direction: column;
    }
    .sns {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 8px;
      @include mq(lg) {
        justify-content: center;
        gap: 15px;
      }

      li {
        position: relative;
        display: inline-block;
        background-color: $key_color_02;
        color: $key_color_01;;
        aspect-ratio: 1 / 1;
        width: 1em;
        height: auto;
        font-size: 25px;
        line-height: 1;
        border-radius: 50px;
        transition: 0.2s ease-out;
        &:hover {
          opacity: 0.6;
        }

        i {
          font-size: 19/25*1em;
        }
      }

      a {
        color: inherit;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }

    }
  }
  #pagetop {
    display: block;
    position: absolute;
    right: 50px;
    top: -22px;
    width: 45px;
    height: 45px;
    background: $key_color_01;
    border: 1px solid #fff;
    // border-radius: 50px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    transform: rotate(45deg);
    @include mq(lg) {
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      -webkit-transform: translateX(-50%) rotate(45deg);
    }
  }
  #pagetop:hover {
    background: #fff;
    border: 1px solid $key_color_01;
  }
  #pagetop {
    &::before {
      content: '';
      display: block;
      position: absolute; /* .navToggleに対して */
      border-bottom: solid 1px $key_color_02;

      top: 24px;
      left: 18px;
      width: 6px;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    &::after {
      content: '';
      display: block;
      position: absolute; /* .navToggleに対して */
      border-bottom: solid 1px $key_color_02;

      top: 21px;
      left: 21px;
      width: 6px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    &::before,
    &::after {
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
  }
  #pagetop:hover {
    &::before,
    &::after {
      border-bottom: solid 1px $key_color_01;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
  }
  .inner .left {
    display: flex;
    font-size: 14px;
    width: percentage(550/1000);
    flex-grow: 1;
    margin-right: 60px;
    @include mq(lg) {
      width: 100%;
    }
    img {
      @include mq(lg) {
        display: block;
        margin: 0 auto;
      }
    }
    .wrap {
      // width: percentage(280/600);
      width: 270px;
      flex-shrink: 0;
      margin-right: 60px;
      @include mq(lg) {
        width: 100%;
      }
    }
  }
  .inner .right {
    margin-top: 0px;
    text-align: center;
    width: 290px;
    padding: 0px 10px;
    @include mq(lg) {
      width: 100%;
    }
  }
  .left dl {
    display: inline-block;
    width: 100%;
    margin-bottom: 40px;
    @include mq(lg) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .left dt {
    max-width: 300px;
    margin-bottom: 10px;
    @include mq(lg) {
      margin-left: auto;
      margin-right: auto;
    }
    img {
      @include mq {
        max-width: 580/750*100%;
      }
    }
  }
  .left dd {
    line-height: 1.5;
    font-size: 12px;
    @include mq(lg) {
      font-size: 13px;
      text-align: center;
      padding-top: 20px;
    }
  }
  .right {
    .button {
      border-color: $key_color_02;
    }
  }
  nav {
    display: flex !important;
    flex-wrap: wrap;
    // width: 230px;
    // margin-top: 0px;
    @include mq(lg) {
      display: none !important;
    }
    .work {
      float: right;
      @include mq(lg) {
        float: none;
      }
    }
  }
  .footer_nav {
    // display: flex;
    // display: -webkit-flex;
    // flex-direction: row;
    // -webkit-flex-direction: row;
    // flex-wrap: wrap;
    // -webkit-flex-wrap: wrap;
    // justify-content: space-between;
    // -webkit-justify-content: space-between;
    // align-items: stretch;
    // -webkit-align-items: stretch;
    // width: 45%;
    // margin: 0 20px 0 0;
    gap: 20px;
    column-count: 2;
    .category {
      padding-bottom: 10px;
    }
    a {
      color: $text_color_02;
      &:hover {
        text-decoration: underline;
      }
    }
    & > li {
      position: relative;
      display: block;
      width: 100%;
      margin-right: 17px;
      margin-bottom: 20px;
      font-size: 14px;
      text-transform: capitalize;
      &::before {
        content: '';
        display: block;
        position: absolute; /* .navToggleに対して */
        border-bottom: solid 1px $key_color_02;

        top: 5.5px;
        left: -10px;
        width: 6px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &::after {
        content: '';
        display: block;
        position: absolute; /* .navToggleに対して */
        border-bottom: solid 1px $key_color_02;

        top: 9.5px;
        left: -10px;
        width: 6px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
    .sub-menu {
      margin-top: 10px;
      li {
        margin-bottom: 6px;
        font-size: 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .right dt {
    width: 100%;
    //font-size: 14px;
    // @include fz_vw(14);
    font-size: 14px;
    margin: 0 auto;
    margin-bottom: 20px;
    @include mq(lg) {
      font-size: 14px;
      padding-top: 30px;
    }
  }
  .right dd {
    display: block;
    //font-size: 33px;
    // padding-left: 18px;
    @include mq(lg) {
      padding-left: 0;
    }
    .lead {
      font-size: 12px;
      padding-bottom: 25px;
      @include mq(lg) {
        padding-bottom: 0;
      }
    }
    .button {
      position: relative;
      font-style: normal;
      font-size: 14px;
      max-width: 250px;
      width: 100%;
      font-size: 14px;
      border: 1px solid $key_color_02;
      @include mq(lg) {
        width: 75%;
        margin-top: 20px;
      }

      &::before ,
      &::after {
        // width: 5px;
        right: 20px;
        @include mq(lg) {
          display: none;
        }
      }

      .fa-envelope {
        font-size: 13px;
        margin-left: 3px;
      }
      &:hover {
        color: $button-color-01;
        text-decoration: none;
        &::before,
        &::after {
          right: 15px;
        }
      }
    }
  }
  .right dd p {
    // @include mb_vw(13);
    // margin-bottom: 13px;
    // text-align: right;
    text-align: center;
  }
  .right aside {
    font-size: 12px;
  }
  .posr {
    position: relative;
    font-size: 28px;
    margin-bottom: 13px;

    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: stretch;
    -webkit-align-items: stretch;
    .tel,
    .fax {
      font-style: oblique;
      // @include pr_vw(25);
      @include mq(lg) {
        text-align: center;
        // @include fz_vw(120);
        padding-left: 0px;
      }
    }
    .fa-phone {
      font-size: 18px;
      padding-left: 10px;

      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      -webkit-flex-direction: row;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      align-items: center;
      -webkit-align-items: center;
      @include mq(lg) {
        // @include fz_vw(85);

        top: 6px;
        // @include lv_vw(150);
      }
    }
  }
  #copyright {
    position: relative;
    // background-color: $key_color_04;
    font-family: $font_family_03;
    color: rgba(255, 255, 255, 0.5);
    font-size: 10px;
    padding: 0 0 30px;
    text-align: center;
    @include mq(lg) {
      padding-top: 15px;
      padding-bottom: 15px;
      margin-top: 30px;
    }
  }
}
