// CONTENTS CONTAINER
%section__container {
  max-width: 1040px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  @include mq(lg) {
    // max-width: 1020px;
    // padding: 0 10px;
  }
  @include mq {
    max-width: 745px;
    padding: 0 30/750*100vw;
  }
}

%margin-section {
  margin-top: map-get($spacers-pc, 'section');
  margin-bottom: map-get($spacers-pc, 'section');
  @include mq {
    margin-top: map-get($spacers-sp, 'section');
    margin-bottom: map-get($spacers-sp, 'section');
  }
  &:first-child { margin-top: 0; }
  &:last-child { margin-bottom: 0; }
}
%margin-component {
  margin-top: map-get($spacers-pc, 'component');
  margin-bottom: map-get($spacers-pc, 'component');
  @include mq {
    margin-top: map-get($spacers-sp, 'component');
    margin-bottom: map-get($spacers-sp, 'component');
  }
  &:first-child { margin-top: 0; }
  &:last-child { margin-bottom: 0; }
}
%margin-element {
  margin-top: map-get($spacers-pc, 'element');
  margin-bottom: map-get($spacers-pc, 'element');
  @include mq {
    margin-top: map-get($spacers-sp, 'element');
    margin-bottom: map-get($spacers-sp, 'element');
  }
  &:first-child { margin-top: 0; }
  &:last-child { margin-bottom: 0; }
}


$grid-gap-pc: 30px;
$grid-gap-sp: map-get($spacers-sp, element);
%grid {
  display: flex;
  flex-wrap: wrap;
  gap: $grid-gap-pc;
  @include mq(md) {
    gap: $grid-gap-sp;
  }
}
%grid-pc {
  @include mq-min(md) {
    display: flex;
    flex-wrap: wrap;
    gap: $grid-gap-pc;
  }
}
%grid-sp {
  @include mq(md) {
    display: flex;
    flex-wrap: wrap;
    gap: $grid-gap-sp;
  }
}
// pc:4 tab:3 sp:2
%grid-col-4a {
  @extend %grid;
  > * {
    width: calc((100% - #{$grid-gap-pc * 3}) / 4);
    @include mq(md) {
      width: calc((100% - #{$grid-gap-sp * 2}) / 3);
    }
    @include mq(sm) {
      width: calc((100% - #{$grid-gap-sp * 1}) / 2);
    }
  }
}
// pc:3 tab:2 sp:1
%grid-col-3a {
  @extend %grid;
  > * {
    width: calc((100% - #{$grid-gap-pc * 2}) / 3);
    @include mq(md) {
      width: calc((100% - #{$grid-gap-sp * 1}) / 2);
    }
    @include mq(sm) {
      width: 100%;
    }
  }
}
// pc:2 tab:1 sp:1
%grid-col-2a {
  @extend %grid;
  > * {
    width: calc((100% - #{$grid-gap-pc * 1}) / 2);
    @include mq(md) {
      width: 100%;
    }
  }
}

