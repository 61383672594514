.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 5px 2em;
  border-width: 1px;
  border-style: solid;
  border-color: $button-color-01;
  border-radius: 32px;
  height: 55px;
  background: $button-color-01;
  color: $text_color_02;
  letter-spacing: 0.1em;
  line-height: 1.4;
  text-align: center;
  font-size: 16px;
  // font-style: oblique;
  > span {
    display: block;
    margin: 0 auto;
  }
  @include mq(md) {
    border-radius: 50px;
  }
  &::before ,
  &::after {
    content: '';
    display: block;
    position: absolute;
    border-bottom: solid 1px #fff;
    top: 50%;
    width: 7px;
    transition: 0.3s ease-in-out;
    transform-origin: right center;
    @include rv_vw(20);
    @include mq(md) {
      right: 20px;
    }
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    background: $key_color_02;
    border: 1px solid $button-color-01;
    color: $button-color-01;
    &::before ,
    &::after {
      border-color: $button-color-01;
      @include rv_vw(15);
      @include mq(md) {
        right: 20px;
      }
    }
  }

  &.__reverse {
    background-color: #fff;
    border-color: $button-color-01;
    color: $button-color-01;
    &::before {
      border-bottom: solid 1px $button-color-01;
    }
    &::after {
      border-bottom: solid 1px $button-color-01;
    }
    &:hover {
      background-color: $button-color-01;
      color: $key_color_02;
      &::before {
        border-bottom: solid 1px $key_color_02;
      }
      &::after {
        border-bottom: solid 1px $key_color_02;
      }
    }
  }

  &.__compact {
    height: 50px;
  }
}


.button_wrap {
  @extend %margin-component;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  section.__filled & {
    @include mq(lg) {
      padding: 0 10px;
    }
    @include mq {
      padding: 0 20px / 750px * 100%;
    }
  }
}