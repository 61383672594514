/**
 * 2.0 - Typography
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  //  clear: both;
  margin: 0 0 0.75em;
  //  padding: 1.5em 0 0;
  &:first-child {
    padding-top: 0;
  }
}
h1 {
  margin-top: 2em;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
}
h2 {
  margin-top: 2em;
  margin-bottom: 1.2em;
  font-size: 22px;
  line-height: 1.5;
  text-align: inherit;
}

h3 {
  margin-top: 2em;
  margin-bottom: 1.2em;
  //  font-size: 22px;
  font-weight: bold;
  font-size: 18px;
}

h4 {
  margin-top: 2em;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0.5em;
}

h5 {
  font-size: 16px;
  margin-top: 1em;
  //  letter-spacing: 0.15em;
}
h6 {
  font-size: 14px;
  font-weight: 800;
}

p {
  margin: 0 0 1.5em;
  padding: 0;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  font-size: 18px;
  font-style: italic;
  line-height: 1.7;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

blockquote.alignleft,
blockquote.alignright {
  font-size: 14px;
  width: 34%;
}

address {
  margin: 0 0 1.5em;
}

strong {
  font-weight: bold;
}

pre {
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #eee;
  text-decoration: none;
}

big {
  font-size: 125%;
}

blockquote,
q {
  quotes: "" "";
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

/**
 * 3.0 - Elements
 */

hr {
  background-color: #bbb;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}

ul,
ol {
  margin: 0 0 1.5em 1em;
  padding: 0;
}

ul {
  list-style: disc;
}

ol {
  counter-reset: item;
}

ol li {
  //	display: block;
  position: relative;
  list-style-type: decimal;
  list-style-position: outside;
  margin-left: 0.5em;
}
ul li {
  margin-left: 0.5em;
}
/*
ol li:before {
  content: counter(item);
  counter-increment: item;
  font-weight: 800;
  left: -1.5em;
  position: absolute;
}
  */

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 1.5em 1.5em;
}

table {
  border-collapse: collapse;
  margin: 0 0 1.5em;
  width: 100%;
}

thead th {
  border-bottom: 2px solid #bbb;
  padding-bottom: 0.5em;
}

th {
  padding: 0.4em;
  text-align: left;
}

tr {
  border-bottom: 1px solid #eee;
}

td {
  padding: 0.4em;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

a {
  //  -webkit-box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
  //  box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
  color: #222;
  //  text-decoration: none;
  text-decoration: underline;
  -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
  transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
  transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
  transition: color 80ms ease-in, box-shadow 130ms ease-in-out,
    -webkit-box-shadow 130ms ease-in-out;
  //
  &:hover {
    opacity: 0.5;
  }
}

a:focus {
  outline: thin dotted;
}

/* Fixes linked images */
/*
a img {
  background: #fff;
  -webkit-box-shadow: 0 0 0 6px #fff;
  box-shadow: 0 0 0 6px #fff;
}
*/

/**
 * 4.0 - Alignment
 */

img {
  height: auto; /* Make sure images are scaled correctly. */
  //  width: inherit;  /* Make images fill their parent's space. Solves IE8. */
  max-width: 100%; /* Adhere to container width. */
}

embed,
iframe,
object {
  margin-bottom: 1.5em;
  max-width: 100%;
}

/**
 * 5.0 - Caption
 */

.wp-caption {
  color: #666;
  //  font-size: 13px;
  //  font-size: 0.8125rem;
  font-size: 1.1rem;
  font-style: italic;
  //  margin-bottom: 1.5em;
  margin-bottom: 20px;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

/**
 * 6.0 - Galleries
 */

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-item a,
.gallery-item a:hover,
.gallery-item a:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  display: inline-block;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

/**
 * 7.0 - Media Elements
 */

.mejs-container {
  margin-bottom: 1.5em;
}

/* Audio Player */

.mejs-controls a.mejs-horizontal-volume-slider,
.mejs-controls a.mejs-horizontal-volume-slider:focus,
.mejs-controls a.mejs-horizontal-volume-slider:hover {
  background: transparent;
  border: 0;
}

/* Playlist Color Overrides: Light */

.wp-playlist-light {
  border-color: #eee;
  color: #222;
}

.wp-playlist-light .wp-playlist-current-item .wp-playlist-item-album {
  color: #333;
}

.wp-playlist-light .wp-playlist-current-item .wp-playlist-item-artist {
  color: #767676;
}

.wp-playlist-light .wp-playlist-item {
  border-bottom: 1px dotted #eee;
  -webkit-transition: background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    color 0.3s ease-in-out;
}

.wp-playlist-light .wp-playlist-item:hover,
.wp-playlist-light .wp-playlist-item:focus {
  border-bottom-color: rgba(0, 0, 0, 0);
  background-color: #767676;
  color: #fff;
}

.wp-playlist-light a.wp-playlist-caption:hover,
.wp-playlist-light .wp-playlist-item:hover a,
.wp-playlist-light .wp-playlist-item:focus a {
  color: #fff;
}

/* Playlist Color Overrides: Dark */

.wp-playlist-dark {
  background: #222;
  border-color: #333;
}

.wp-playlist-dark .mejs-container .mejs-controls {
  background-color: #333;
}

.wp-playlist-dark .wp-playlist-caption {
  color: #fff;
}

.wp-playlist-dark .wp-playlist-current-item .wp-playlist-item-album {
  color: #eee;
}

.wp-playlist-dark .wp-playlist-current-item .wp-playlist-item-artist {
  color: #aaa;
}

.wp-playlist-dark .wp-playlist-playing {
  background-color: #333;
}

.wp-playlist-dark .wp-playlist-item {
  border-bottom: 1px dotted #555;
  -webkit-transition: background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    color 0.3s ease-in-out;
}

.wp-playlist-dark .wp-playlist-item:hover,
.wp-playlist-dark .wp-playlist-item:focus {
  border-bottom-color: rgba(0, 0, 0, 0);
  background-color: #aaa;
  color: #222;
}

.wp-playlist-dark a.wp-playlist-caption:hover,
.wp-playlist-dark .wp-playlist-item:hover a,
.wp-playlist-dark .wp-playlist-item:focus a {
  color: #222;
}

/* Playlist Style Overrides */

.wp-playlist {
  padding: 0.625em 0.625em 0.3125em;
}

.wp-playlist-current-item .wp-playlist-item-title {
  font-weight: 700;
}

.wp-playlist-current-item .wp-playlist-item-album {
  font-style: normal;
}

.wp-playlist-current-item .wp-playlist-item-artist {
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 800;
  letter-spacing: 0.1818em;
  text-transform: uppercase;
}

.wp-playlist-item {
  padding: 0 0.3125em;
  cursor: pointer;
}

.wp-playlist-item:last-of-type {
  border-bottom: none;
}

.wp-playlist-item a {
  padding: 0.3125em 0;
  border-bottom: none;
}

.wp-playlist-item a,
.wp-playlist-item a:focus,
.wp-playlist-item a:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

.wp-playlist-item-length {
  top: 5px;
}

/**
 * 8.0 - RTL
 */

.rtl th {
  text-align: right;
}

.rtl ol {
  counter-reset: item;
}

.rtl ol li:before {
  left: auto;
  right: -1.5em;
}

.rtl li > ul,
.rtl li > ol {
  margin-left: 0;
  margin-right: 1.5em;
}

.rtl .mejs-offscreen {
  right: -10000px;
}
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}
img.alignright,
img.alignleft {
  @media only screen and(max-width: 750px) {
    float: none;
    margin: auto;
  }
  @media only screen and(min-width: 751px) {
    max-width: 50%;
  }
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
