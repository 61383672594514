// Colors
$color__attention: #f00;

// tel link
a[href^="tel:"] {
  color: inherit;
  @include mq-min {
    pointer-events: none;
  }
}

// hover
.hover {
  transition: .3s ease;
  &:hover {
    opacity: 0.6;
  }
}

.hide_pc {
  @include mq-min {
    display: none;
  }
}

// BODY-TEXT
.paragraph {
  line-height: 2;
  font-size: 15px;
}
.paragraph__large {
  line-height: 1.8;
  font-size: 16px;
}
.paragraph__small {
  line-height: 2.36;
  font-size: 14px;
}
.paragraph__x-small {
  @extend .paragraph__small;
  line-height: 1.8;
}
.paragraph__xx-small {
  font-size: 12px;
  line-height: 1.8;
}

// TABLE
.table-basic {
  width: 100%;
  border: none;
  border-collapse: collapse;
  font-size: 14px;
  line-height: 1.6;
  th,
  td {
    padding: 25px 0;
    padding-left: 20px;
    border: 1px solid #bfbfbf;
    border-width: 0 0 1px;
    text-align: left;
    &:last-child {
      padding-right: 20px;
    }
  }
  th {
    width: percentage(125px/480px);
    min-width: calc(20px + 5em);
    color: $key_color_01;
    font-size: 15px;
    font-weight: normal;
  }
}

// LAYOUT
%basic-layout {
  & + & {
    margin-top: 140px;
    @include mq(md) {
      margin-top: 80px;
    }
  }
  .separate-column__container,
  .portfolio-detail__container {
    display: flex;
    > * {
      margin: 0 2.5%;
    }

  }
  &.__default {
    .separate-column__container,
    .portfolio-detail__container {
      flex-direction: row;
      & > *:first-child {
        margin-left: 0;
      }
      & > *:last-child {
        margin-right: 0;
      }
    }
  }
  &.__reverse {
    .separate-column__container,
    .portfolio-detail__container {
      flex-direction: row-reverse;
      & > *:first-child {
        margin-right: 0;
      }
      & > *:last-child {
        margin-left: 0;
      }
    }
  }
  .__figure {
    > img {
      display: block;
      width: 100%;
      max-width: none;
    }
  }
  @include mq(md) {
    .separate-column__container,
    .portfolio-detail__container {
      display: block;
      > * {
        margin: 0 0 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .__figure,
  .__content {
    flex: 0 1 auto;
  }
  .__figure {
    width: 50%;
    @include mq {
      width: auto;
    }
  }
  .__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 45%;
    @include mq {
      width: auto;
    }
    > .separate-column__link,
    > .portfolio__link {
      flex: 1 1 auto;
      display: flex;
      align-items: flex-end;
      .button {
        width: 100%;
        margin: 30px auto 0;
        @include mq {
          max-width: 400px;
        }
      }
    }
  }
  h2,
  p {
    flex: 0 1 auto;
  }
  h2 {
    @extend .heading__level-2;
    margin: 15px 0;
    text-align: left;
  }
  p {
    @extend .paragraph__small;
    & + p {
      margin-top: 20px;
    }
  }
  > .separate-column__link,
  > .portfolio__link {
    @extend %margin-component;
  }
  > .separate-column__link,
  > .portfolio__link {
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
  }
}
