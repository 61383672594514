$z-drawer-close: 5;
$z-drawer-navi: 10;
$z-drawer-open: 15;
$drawer-width: 280px;
$drawer-transition-duration: .45s;

.l-wrapper {
  transition: transform $drawer-transition-duration cubic-bezier(0.215, 0.61, 0.355, 1);
}

.l-drawer {
  &__open,
  &__close {
    @include mq-min(lg) {
      display: none;
    }
    cursor: pointer;
    position: fixed;
  }
  // 
  &__open {
    z-index: $z-drawer-open;
    top: 8px;
    right: 8px;
    width: 48px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    transition: background $drawer-transition-duration, transform $drawer-transition-duration cubic-bezier(0.215, 0.61, 0.355, 1);
    @include mq-min(md) {
      color: #fff;
      top: 16px;
      right: 16px;
      background: $key_color_01;
    }
    &::before,
    &::after {
      content: "";
    }
    & span,
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: 30%;
      width: 40%;
      border-bottom: 2px solid currentColor;
      transition: transform $drawer-transition-duration cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &::before {
      transform: translateY(-8px);
    }
    &::after {
      transform: translateY(8px);
    }
  }
  &__close {
    z-index: $z-drawer-close;
    inset: -10vh 0;
    pointer-events: none;
    transition: background $drawer-transition-duration;
  }

  // navi
  &__container {
    @include mq-min(lg) {
      display: none;
    }
    z-index: $z-drawer-navi;
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 10px;
    padding-top: 64px;
    padding-bottom: 64px;
    box-sizing: border-box;
    // background: rgba(0,0,0,.6);
    background: #fff;
    transform: translateX(100%);
    transition: transform $drawer-transition-duration cubic-bezier(0.215, 0.61, 0.355, 1);
    @include mq-min(md) {
      width: $drawer-width;
    }
    &__logo {
      text-align: center;
      margin-bottom: 30px;
      img {
        max-width: 60%;
      }
    }
    &__contact {
      margin-top: 30px;
      text-align: center;
      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: auto;
        min-width: 200px;
        min-height: 2.5em;
        border-radius: 50px;
        font-size: 2rem;
        background: $button-color-01;
        border: 1px solid $button-color-01;
        color: $text_color_02;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      font-size: 1.8rem;
      a {
        display: block;
        padding: 1em 2em;
        // color: white;
        text-decoration: inherit;
        transition: background $drawer-transition-duration;
        &:hover {
          background: rgba(0,0,0,.3);
        }
      }
    }
    li {
      line-height: 1.4;
      text-align: center;
      + li {
        // border-top: 1px solid rgba(255,255,255,.6);
        // border-top: 1px solid;
      }
    }
  }

}


#drawer {
  display: none;

  // when drawer open
  &:checked {
    + .l-drawer {
      &__open {
        @include mq-min(md) {
          transform: translateX($drawer-width*-1);
        }
      }
      &__open span {
        transform: scaleX(0);
      }
      &__open::before {
        transform: rotate(135deg) scaleX(1.2);
      }
      &__open::after {
        transform: rotate(-135deg) scaleX(1.2);
      }
    }
    ~ .l-drawer {
      &__close {
        pointer-events: auto;
        background: rgba(0,0,0,.4);
      }
      &__container {
        transform: none;
      }
    }
  }
}

