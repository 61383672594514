.googlemap {
  @extend %margin-component;
  iframe {
    width: 100%;
    height: 350px;
    border: none;
    @include mq {
      height: 150px;
    }
  }
}
