// Assets Pathes
$img-path: '../img/';
$compo-image: $img-path + 'component/';

// Font Families
%roboto {
  font-family: 'Roboto', sans-serif;
}

// Aside Navigations
%aside-heading {
  width: 100%;
  margin: 0;
  border-radius: 50px;
  padding: 10px 0;
  background-color: $key-color-01;
  color: $text_color_02;
  font-size: 15px;
}
%aside-link-list__item {
  // display: block;
  padding: 3px 0;
  line-height: 1.4;
  font-size: 12px;
  font-weight: normal;
  &:hover {
    text-decoration: underline;
  }
}
%aside-link-list__new {
  padding-left: 20px;
  color: #f39800;
  text-decoration: none;
  font-size: 10px;
}

// Tri-Column Unit
@mixin tri-column__unit($is_break: 'true') {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;

  @if $is_break == 'true' {
    @include mq(md) {
      flex-direction: column;
      gap: 20 / 750 * 100vw;
    }
  }
}
@mixin tri-column__item($is_break: 'true') {
  flex: 0 1 calc((100% - 40px) / 3);
}
