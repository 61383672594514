.heading__level-2__l {
  letter-spacing: 0.05em;
  text-align: center;
  font-size: 32px;
  font-weight: normal;
}

.heading__level-2 {
  font-size: 28px;
  font-style: italic;
}

.heading__level-3 {
  line-height: 1.5;
  font-weight: normal;
  font-size: 24px;
}

// Section Heading
.heading__section {
  @extend .heading__level-2__l;
  position: relative;
  font-style: italic;
  span {
    display: block;
    // padding-top: 10px;
    padding-bottom: 20px;
    color: $key_color_01;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: normal;
    @include mq {
      font-size: 11px;
    }
  }
}
