.operation-flow {
  counter-reset: operation-flow;
  li {
    background: #eeeeee;
    margin: 20px 0 0;
    counter-increment: operation-flow;
    @include mq(md) {
      margin-top: 30px;
    }
    &:first-child {
      margin-top: 0;
    }
    &:nth-child(-n + 10) {
      dl::before {
        content: 'FLOW 0' counter(operation-flow);
      }
    }
    &:nth-child(n + 10) {
      dl::before {
        content: 'FLOW ' counter(operation-flow);
      }
    }
  }
  &__container {
    @extend %section__container;
    display: flex;
    align-items: center;
    @include mq {
      position: relative;
      display: block;
      padding: 0 0 30px;
    }
  }
  .__figure {
    flex: 0 1 percentage(280px / 1000px);
    margin: 0 percentage(15px / 1000px);
    img {
      display: block;
      width: 100%;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    @include mq {
      margin: 0;
      padding: 10px 10px 0;
    }
  }
  dl {
    flex: 0 1 percentage((1000px - 280px - 30px) / 1000px);
    display: block;
    margin: 0 percentage(15px / 1000px);
    padding: 25px 0 20px;
    @include mq {
      margin: 15px 0 0;
      padding: 0 10px;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &::before {
      display: inline-block;
      margin-right: 15px;
      border-radius: 50px;
      padding: 3px 15px 3px 20px;
      background-color: $key_color_01;
      color: $text_color_02;
      letter-spacing: 1px;
      line-height: 1.8;
      text-align: center;
      vertical-align: middle;
      font-size: 16px;
      font-style: italic;
      font-family: $font_family_03;
      @include mq(lg) {
        margin: 0 0 5px;
      }
      @include mq(md) {
        position: absolute;
        top: -8px;
        left: 50%;
        margin: 0;
        width: 110px;
        padding: 7px 0;
        transform: translateX(-50%);
      }
    }
  }
  dt {
    display: inline-block;
    margin: 0;
    color: $key_color_01;
    line-height: 1.8;
    vertical-align: middle;
    font-weight: normal;
    font-size: 24px;
    @include mq(lg) {
      display: block;
    }
    @include mq(md) {
      margin-top: 15px;
      margin-bottom: 10px;
      line-height: 1.4;
    }
  }
  dd {
    @extend .paragraph__xx-small;
    margin: 15px 0 0;
  }
  & + p {
    max-width: 410px;
    margin: 60px auto 0;
    @include mq {
      max-width: none;
      width: 95%;
      margin-top: 20px;
    }
  }
}
