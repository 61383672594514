%contact-box-tel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font_family_03;

  i {
    font-size: 0.8em;
    color: $key_color_01;
  }
}

.contact-information {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 0;
  background: $key_color_02;
  @include mq {
    padding: 30/750*100vw;
  }
  &__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0;
    @include mq(md) {
      flex-direction: column;
      gap: 30/750*100vw;
    }
  }
  &__box {
    width: 50%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    @include mq {
      width: 100%;
      padding: 0;
    }
    &:not(:first-child) {
      @include mq-min(md) {
        border-left: 1px solid #bfbfbf;
      }
      @include mq(md) {
        border-top: 1px solid #bfbfbf;
        padding-top: 30/750*100vw;
      }
    }

    h4 {
      margin: 0 0 20px;
      font-weight: inherit;
      text-align: center;
      @include mq(md) {
        width: 100%;
        font-size: 16px;
      }
    }

    i[class^=fa] {
      margin-right: 0.5em;
      font-size: 1em;
    }

    .tel {
      @extend %contact-box-tel;
      @include mq-min {
        font-size: 4rem;
        line-height: 55px;//ボタンと揃える
      }
      @include mq {
        font-size: 36px;
      }
      @include mq(sm) {
        font-size: 30px;
      }
    }

    .notice {
      margin-top: 20px;
      font-size: 1.2rem;
    }
  
  
    &.--tel {

    }
    &.--mail {

    }
  }

  .button {
    width: 100%;
    max-width: 350px;
    text-align: center;
    font-size: 15px;
    font-style: normal;
  }

}


.c-contact-box {
  $elem: #{&};
  // 
  @extend %margin-component;
  display: flex;
  justify-content: center;
  @include mq {
    flex-direction: column;
  }

  &.--col-1 {
    @include mq-min(md) {
      #{$elem}__item {
        width: 100%;
        flex-direction: row;
      }
      #{$elem}__heading {
        flex: 1 1 percentage(460px / 960px);
        font-size: 2rem;
      }
      #{$elem}__body {
        flex: 1 1 50%;
      }
    }
  }
  &.--col-2 {
    @extend %grid-col-2a;
    #{$elem}__item {
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid $key_color_01;
    border-radius: 10px;
    overflow: hidden;
  }
  &__heading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 20px 10px;
    background-color: $key_color_01;
    color: $key_color_02;
    letter-spacing: 0.05em;
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    @include mq(md) {
      padding: 15px 0;
    }
  }
  &__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 10px;
    text-align: center;
    .notice {
      display: block;
      margin: 20px 0 0;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: normal;
      @include mq {
        margin-top: 10px;
      }
    }
    .tel ,
    .mail {
      @extend %contact-box-tel;
      margin-top: 10px;
      font-size: 2.4rem;
      font-weight: bold;
      font-style: oblique;
      font-family: $font_family_03;
      letter-spacing: 0.05em;
      &:first-child { margin-top: 0; }
      &:last-child { margin-bottom: 0; }
      i {
        margin-right: 10px;
        color: $key_color_01;
        vertical-align: middle;
      }
    }
  }
}


// 
.section__header {
  aside {
    display: flex;
    width: auto;
    max-width: 960px;
    margin: 40px auto 0;
    &:first-child {
      margin-top: 0;
    }
    @include mq {
      display: block;
    }

    dl {
      flex: 1 1 percentage(460px / 960px);
      margin: 0 percentage(20px / 960px);
      border: 1px solid $key_color_01;
      border-radius: 10px;
      overflow: hidden;
      @include mq {
        margin: 15px 0 0;
      }
      &:first-child {
        margin-left: 0;
        @include mq {
          margin-top: 0;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
    dt {
      width: 100%;
      margin: 0;
      padding: 20px 0;
      background-color: $key_color_01;
      color: $key_color_02;
      letter-spacing: 0.05em;
      text-align: center;
      font-size: 16px;
      font-weight: normal;
      @include mq(md) {
        padding: 15px 0;
      }
    }
    dd {
      padding: 30px 0 35px;
      letter-spacing: 0.05em;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      font-style: oblique;
      font-family: $font_family_03;
      small {
        display: block;
        margin: 20px 0 0;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        @include mq {
          margin-top: 10px;
        }
      }
      i {
        margin-right: 10px;
        color: $key_color_01;
        vertical-align: middle;
        &.fa-phone {
          font-size: 28px;
        }
        &.fa-flip-horizontal {
          transform: scaleX(1);
          &::before {
            display: block;
            transform: scaleX(-1);
          }
        }
      }
    }

    dl:only-child {
      display: flex;
      align-items: center;
      @include mq {
        display: block;
      }
      > * {
        flex: 1 1 50%;
      }
      dt {
        padding: 40px 0;
        font-size: 20px;
        @include mq {
          padding: 15px 0;
          font-size: 14px;
        }
      }
      dd {
        padding: 0;
        @include mq {
          padding: 30px 0 35px;
        }
      }
    }
  }

}