@charset "UTF-8";

////////////////////////////////
//テンプレの種類を問わず、使用頻度の高いミックスイン。
////////////////////////////////

//clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: '.';
    display: block;
    height: 0;
    overflow: hidden;
  }
  &:after {
    clear: both;
  }
}

////////////////////////////////
//以下、テンプレ固有ミックスイン。自由に追加する。
////////////////////////////////

//レスポンシブ
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin mq-min($breakpoint: md) {
  @media #{map-get($breakpoints_min, $breakpoint)} {
    @content;
  }
}

// 第一引数で基準となる画面幅のフォントサイズ、第二引数で基準となる画面幅を入力
@function get_vw($size, $viewport: 1400) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size: 10) {
  //font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

@mixin wt_vw($font_size: 10) {
  //width: $font_size * 1px;
  width: get_vw($font_size);
}

@mixin ht_vw($font_size: 10) {
  //height: $font_size * 1px;
  height: get_vw($font_size);
}

@mixin mb_vw($font_size: 10) {
  //margin-bottom: $font_size * 1px;
  margin-bottom: get_vw($font_size);
}

@mixin mt_vw($font_size: 10) {
  //margin-top: $font_size * 1px;
  margin-top: get_vw($font_size);
}

@mixin mr_vw($font_size: 10) {
  //margin-right: $font_size * 1px;
  margin-right: get_vw($font_size);
}

@mixin ml_vw($font_size: 10) {
  //margin-right: $font_size * 1px;
  margin-left: get_vw($font_size);
}

@mixin pt_vw($font_size: 10) {
  //padding-top: $font_size * 1px;
  padding-top: get_vw($font_size);
}

@mixin pb_vw($font_size: 10) {
  //padding-bottom: $font_size * 1px;
  padding-bottom: get_vw($font_size);
}

@mixin pl_vw($font_size: 10) {
  //padding-left: $font_size * 1px;
  padding-left: get_vw($font_size);
}

@mixin pr_vw($font_size: 10) {
  //padding-right: $font_size * 1px;
  padding-right: get_vw($font_size);
}

@mixin pv_vw($font_size: 10) {
  //padding-top: $font_size * 1px;
  padding-top: get_vw($font_size);
  //padding-bottom: $font_size * 1px;
  padding-bottom: get_vw($font_size);
}

@mixin ph_vw($font_size: 10) {
  //padding-left: $font_size * 1px;
  padding-left: get_vw($font_size);
  //padding-right: $font_size * 1px;
  padding-right: get_vw($font_size);
}

@mixin tv_vw($font_size: 10) {
  //top: $font_size * 1px;
  top: get_vw($font_size);
}

@mixin rv_vw($font_size: 10) {
  //right: $font_size * 1px;
  right: get_vw($font_size);
}

@mixin lv_vw($font_size: 10) {
  //left: $font_size * 1px;
  left: get_vw($font_size);
}
