.section {
  // 
  position: relative;
  padding: $section-padding-vertical 0;
  @include mq(md) {
    padding: $section-padding-vertical-sp 0;
  }
  
  // section before footer
  .main-contents > &:last-child {
    padding-bottom: $section-padding-vertical * 1.5;
    @include mq(md) {
      padding-bottom: $section-padding-vertical-sp * 1.5;
    }
  }
  

  // 基本（背景無し）
  &.--basic {
    // background-color: #ff9;
    & + & {
      padding-top: 0;
    }
  }


  // 背景
  // =====================

  // ベタ塗り
  &.--bg-color {
    background-color: rgba($key_color_01, .3);
  }
  // ポリゴン
  &.--bg-polygon {
    position: relative;
    background-image: url(../img/common/bg_polygon.png);
    background-position: center;
    background-repeat : repeat;
    // background-color  : $key_color_01;
    // background-blend-mode : hard-light;
    // mix-blend-mode: hard-light;
    &::before {
      background-color  : rgba($key_color_01, .5);
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      mix-blend-mode: color-burn;
      pointer-events: none;
    }
  }


  // クリッピング
  // =====================

  // 上
  &.--clip-top {
    // padding-top: $section-padding-vertical + $section-clip-height / 2;
    clip-path: polygon(
      calc((100vw - #{$section-clip-width}) / 2) #{$section-clip-height},
      50% 0%,
      calc(100% + (#{$section-clip-width} - 100vw) / 2) #{$section-clip-height},
      calc(100% + (#{$section-clip-width} - 100vw) / 2) 100%,
      calc((100vw - #{$section-clip-width}) / 2) 100%
    );
    @include mq(md) {
      clip-path: polygon(
        calc((100vw - #{$section-clip-width-sp}) / 2) #{$section-clip-height-sp},
        50% 0%,
        calc(100% + (#{$section-clip-width-sp} - 100vw) / 2) #{$section-clip-height-sp},
        calc(100% + (#{$section-clip-width-sp} - 100vw) / 2) 100%,
        calc((100vw - #{$section-clip-width-sp}) / 2) 100%
      );
    }
  }
  // 下
  &.--clip-bottom {
    padding-bottom: $section-padding-vertical + $section-clip-height / 2;
    clip-path: polygon(
        calc((100vw - #{$section-clip-width}) / 2) 0%,
        calc(100% + (#{$section-clip-width} - 100vw) / 2) 0%,
        calc(100% + (#{$section-clip-width} - 100vw) / 2) 100%,
        50% calc(100% - #{$section-clip-height}),
        calc((100vw - #{$section-clip-width}) / 2) 100%
      );
    + .section {
      margin-top: -$section-clip-height;
    }
    @include mq(md) {
      padding-bottom: calc(#{$section-padding-vertical-sp} + #{$section-clip-height-sp / 2});
      clip-path: polygon(
          calc((100vw - #{$section-clip-width-sp}) / 2) 0%,
          calc(100% + (#{$section-clip-width-sp} - 100vw) / 2) 0%,
          calc(100% + (#{$section-clip-width-sp} - 100vw) / 2) 100%,
          50% calc(100% - #{$section-clip-height-sp}),
          calc((100vw - #{$section-clip-width-sp}) / 2) 100%
        );
      + .section {
        margin-top: -$section-clip-height-sp;
      }
    }
  }
  // 上下
  &.--clip-both {
    // padding-top: $section-padding-vertical + $section-clip-height / 2;
    padding-bottom: $section-padding-vertical + $section-clip-height / 2;
    clip-path: polygon(
        calc((100vw - #{$section-clip-width}) / 2) #{$section-clip-height},
        50% 0%,
        calc(100% + (#{$section-clip-width} - 100vw) / 2) #{$section-clip-height},
        calc(100% + (#{$section-clip-width} - 100vw) / 2) 100%,
        50% calc(100% - #{$section-clip-height}),
        calc((100vw - #{$section-clip-width}) / 2) 100%
      );
    // margin-top: -$section-clip-height;
    + .section {
      margin-top: -$section-clip-height;
    }
    @include mq(md) {
      padding-bottom: calc(#{$section-padding-vertical-sp} + #{$section-clip-height-sp / 2});
      clip-path: polygon(
          calc((100vw - #{$section-clip-width-sp}) / 2) #{$section-clip-height-sp},
          50% 0%,
          calc(100% + (#{$section-clip-width-sp} - 100vw) / 2) #{$section-clip-height-sp},
          calc(100% + (#{$section-clip-width-sp} - 100vw) / 2) 100%,
          50% calc(100% - #{$section-clip-height-sp}),
          calc((100vw - #{$section-clip-width-sp}) / 2) 100%
        );
      + .section {
        margin-top: -$section-clip-height-sp;
      }

    }
  }
}

.section__container {
  @extend %section__container;
  position: relative;
  z-index: 1;
  &:not(:first-child) {
    margin-top: 40px;
    @include mq {
      margin-top: 30px;
    }
  }
  .--filled & {
    max-width: none;
    padding: 0;
    @include mq(lg) {
      max-width: none;
      padding: 0;
    }
    @include mq {
      max-width: none;
      padding: 0;
    }
  }
}


.section__header {
  @extend %margin-component;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  h2 {
    @extend .heading__section;
    margin-top: 40px;
    &:first-child {
      margin-top: 0;
    }
  }
  p {
    @extend .paragraph__large;
    margin-top: 40px;
    text-align: center;
    @include mq {
      margin-top: 30px;
    }
    &:first-child {
      margin-top: 0;
    }
    em {
      color: $color__attention;
      font-style: normal;
    }
  }
}

