.cover-art {
  position: relative;
  text-align: center;
  color: $text_color_02;


  &:first-child {
    margin-top: $header-height-pc;
    @include mq(md) {
      margin-top: $header-height-sp;
    }
  }

  &__container {
    height: 280px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include mq(md) {
      aspect-ratio: 375 / 150;
      width: 100%;
      height: auto;
    }
    .__content {
      position: relative;
      max-width: 1000px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      @include mq(md) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
      }
    }
  }
  h1 {
    height: 100%;
    width: 400px;
    margin: 0;
    padding-top: 80px;
    background-color: rgba($key_color_01, 0.85);
    letter-spacing: 4px !important;
    text-align: center;
    font-size: 38px;
    font-weight: normal;
    font-style: italic;
    @include mq(md) {
      width: 100%;
      height: 85px;
      padding-top: 0px;
      font-size: 32px;
    }
    span {
      color: rgba(255, 255, 255, 0.8);
      line-height: 2;
      letter-spacing: 2px;
      font-weight: normal;
      font-family: $font_family_03;
      font-style: oblique;
      font-size: 14px;
      @include mq(md) {
        font-size: 15px;
      }
    }
  }
}
