.feature-list {
  ul {
    @extend %grid-col-3a;
  }
  li {
    border: 1px solid #bfbfbf;
    background: #fff;
  }
  .button {
    // border-color: #fff;
    &:hover {
      // border-color: $key_color_01;
    }
  }
  dt {
    position: relative;
    > img {
      aspect-ratio: 4 / 3;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    > span {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 85%;
      margin: 0 auto;
      transform: translateY(50%);
    }
  }
  dd {
    @extend .paragraph__x-small;
    padding: 50px 30px 60px;
    @include mq(md) {
      padding: 40px 30px;
    }
  }
}
