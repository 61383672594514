
/* GALLERY */
// ※なかはた農園のテーマからコピーしたため、
// 　他のcssの記述と統一されていないかもしれない

// px値をvw値に変換する
@function vw($px) {
  @return $px / 750 * 100vw;
}


.gallery {
  &.swiper-container {
    margin: $section-padding-vertical 0;
    @include mq {
      margin: $section-padding-vertical-sp 0;
    }
  }
  &__slide {
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    @include mq-min {
      max-width: 230px;
    }
    @include mq {
      width: vw(300);
    }
  }
  &__item {
    position: relative;
    z-index: 0;
    img {
      @include mq {
        width: 100%;
      }
    }
  }
  &__over {
    // color: $clr-cc;
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba($clr-base, 0.7);
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    opacity: 0;
    transition: opacity ease-out 0.2s;
  }
  &__dl {
    display: block;
    width: 80%;
    text-align: center;
    margin: 0 auto;
  }
  @include mq-min {
    &__link {
      transition: none;
    }
    &__link:hover {
      opacity: 1;
      .gallery__over {
        opacity: 1;
      }
    }
  }
}


.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: none;
}
.swiper-button-next,
.swiper-button-prev {
  background-color: $key-color-01;
  margin-top: -15px;
  z-index: 1001;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    border-style: solid;
    border-color: #fff;
    border-width: 0;
  }
  width: 30px;
  height: 30px;
}
.swiper-button-next {
  right: 0;
  &:after {
    border-top-width: 2px;
    border-right-width: 2px;
    transform: translate(-70%, -50%) rotate(45deg);
  }
}
.swiper-button-prev {
  left: 0;
  &:after {
    border-top-width: 2px;
    border-left-width: 2px;
    transform: translate(-30%, -50%) rotate(-45deg);
  }
}