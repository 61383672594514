.contact__form {
  $table-border: #e6e6e6;
  $input-border: #d2d2d2;
  $input-bg: #f8f8f8;
  $th: #eee;
  display: table;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $table-border;
  font-size: 16px;
  background-color: #fff;

  input,
  select,
  textarea {
    width: 100%;
    border: 1px solid $input-border;
    font-size: 15px;
    background-color: $input-bg;
  }

  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  select {
    height: 45px;
    padding: 0 15px;
  }
  textarea {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  textarea {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }


  select,
  input[type='tel'],
  input[type='email'],
  textarea {
    // background: $key_color_02;
  }

  input[type='tel'] {
    // width: 120px;
  }

  input[type='email'],
  textarea {
    // max-width: 520px;
    // width: 100%;
    // @include mq {
    //   max-width: none;
    // }
  }

  input[type='checkbox'] {
    width: auto;
  }
  input[type='radio'] {
    width: auto;
    display: none;
    & + .pseudo-radio {
      padding-left: 35px;
      position: relative;
      margin-right: 50px;
      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
        border: 1px solid #999;
        border-radius: 50%;
        background: #fff;
        content: '';
      }
    }
    &:checked {
      & + .pseudo-radio {
        &::after {
          position: absolute;
          top: 3px;
          left: 3px;
          display: block;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background: $key_color_01;
          content: '';
        }
      }
    }
  }

  select {
    border-radius: 0;
    padding-right: 50px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  dl {
    display: table-row;
  }
  dt,
  dd {
    display: table-cell;
    vertical-align: middle;
    border: 1px solid $table-border;
  }
  dt {
    width: 22.5%;
    padding: 30px 0 30px 30px;
    background-color: $th;
    vertical-align: top;
    font-weight: bold;
  }
  .__require {
    > dt {
      &::after {
        margin-left: 5px;
        color: $color__attention;
        vertical-align: text-top;
        font-size: 10px;
        content: '※';
      }
    }
  }
  dd {
    width: 77.5%;
    padding: 15px 25px;
    small {
      display: block;
      margin: 5px 0 0;
      font-size: 12px;
    }
    dl {
      &,
      dt,
      dd {
        border: none;
      }
      dt,
      dd {
        padding: 5px 0;
      }
      dt {
        width: 30%;
        padding-top: 15px;
        background-color: $key_color_02;
        font-weight: normal;
      }
      dd {
        width: 70%;
      }
      input[type='text'],
      input[type='tel'] {
        width: 100%;
        background-color: $key_color_02;
      }
    }
    > div {
      display: flex;
      align-items: center;
      @include mq {
        display: block;
      }
      label {
        flex: 0 0 250px;
        display: flex;
        align-items: center;
        margin: 0 0 0 20px;
        @include mq {
          margin: 10px 0 0;
        }
        &:first-child {
          margin-left: 0;
          @include mq {
            margin-top: 0;
          }
        }
        input {
          flex: 0 0 210px;
          margin: 0 0 0 auto;
          display: block;
          @include mq {
            flex-basis: 80%;
          }
        }
      }
      &.__linear {
        @include mq {
          display: flex;
          flex-wrap: wrap;
        }
        > input[type='tel'] {
          @include mq {
            flex-grow: 1;
            flex-shrink: 1;
            width: 0;
          }
        }
        > div {
          @include mq {
            flex-grow: 1;
            flex-shrink: 1;
          }
          select {
            @include mq {
              width: 100%;
              padding-right: 20px;
            }
          }
        }
      }
      span {
        margin: 0 10px;
      }
      div {
        position: relative;
        &:before,
        &:after {
          position: absolute;
          top: 50%;
          width: 7px;
          display: block;
          height: 1px;
          background-color: #000;
          transition: 0.2s ease-out;
          transform: translateX(-50%) translateY(-50%);
          content: '';
        }
        &::before {
          right: 20px;
          transform: rotate(45deg);
        }
        &::after {
          right: 15px;
          transform: rotate(-45deg);
        }
      }
    }
    > ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 15px 25px;
    }
  }

  @include mq {
    > dl {
      &,
      > dt,
      > dd {
        display: block;
        width: 100%;
        border-width: 1px 0 0;
      }
      > dt,
      > dd {
        padding: 20px 10px;
      }
      &:first-child {
        &,
        > dt {
          border: none;
        }
      }
    }
  }
}
.contact__submit {
  @extend %margin-component;
  .button {
    width: 100%;
    max-width: 280px;
    outline: none;
    transition: .3s ease-in-out;
    transition-property: color, background-color;
    cursor: pointer;
    @include mq {
      max-width: none;
    }
  }
}


.contact-agreement {
  @extend %margin-component;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  &__heading {
    font-size: 100%;
    font-weight: 700;
    text-align: center;
  }
  &__content {
    height: 180px;
    border: 1px solid #000;
    padding: 1.25em;
    line-height: 1.5;
    font-size: 1.4rem;
    overflow: auto;
    background-color: #fff;

    h2 {
      margin-top: 1em;
      font-size: 1.2em;
      font-weight: bold;
    }
    p {
      margin-top: 1em;
    }
    > :first-child {
      margin-top: 0;
    }
  }
  &__checkbox {
    text-align: center;
    @extend %margin-element;
    .wpcf7-list-item {
      margin: 0;
    }
  }
}

.form-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // height: 300px;
  max-height: 50vh;
  overflow: auto;
  text-align: center;
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
  &__heading {
    margin-bottom: 1.5em;
    line-height: 1.4;
    font-size: 1.8rem;
  }
  &__description {
    line-height: 1.8;
    font-size: 1.6rem;
    @include mq {
      font-size: 1.4rem;
    }
  }
  &__navigation {
  }
}