%portfolio__unit {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $text_color_01;
    opacity: 0;
    z-index: 9;
    transition: 0.3s ease-in-out;
  }
  > img {
    display: block;
    width: 100%;
  }
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    inset: 0;
    text-align: center;
    color: $text_color_02;
    padding: 10px;
    letter-spacing: 2px;
    border-bottom: 1px solid $key_color_02;
    z-index: 15;
    opacity: 0;
    transition: 0.3s ease-in-out;
    small {
      margin-bottom: 0.5em;
    }
  }
  a:hover > & {
    &::after {
      opacity: 0.5;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
    > span {
      opacity: 1;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
  }
}

.portfolio__index {
  .portfolio__list {
    @extend %grid-col-3a;
    a {
      display: block;
      div {
        @extend %portfolio__unit;
      }
      @include mq {
      }
    }
  }
}
.portfolio__pickup {
  .portfolio__container {
    position: relative;
  }
  .portfolio__list {
    margin: 0 auto;
    @media only screen and (max-width: 1120px) {
      width: 1000px / 1120px * 100%;
    }
    a {
      position: relative;
      display: block;
      margin-left: 10px;
      margin-right: 10px;
      padding-top: 25px;
      @include mq(md) {
        padding-top: 15px;
      }
      div {
        @extend %portfolio__unit;
      }
      p {
        @include fz_vw(14);
        margin-top: 10px;
        @include mq(md) {
          font-size: 15px;
        }
      }
    }
    img {
      @include mq(md) {
        width: 100%;
      }
    }
    .new {
      top: 0px;
      right: -10px;
      @include mq(md) {
        right: -3px;
      }
    }
  }

  .slick-arrow {
    top: 0;
    bottom: 0;
    width: 21px;
    height: 41px;
    margin: auto;
    z-index: 2;
    transform: translate(0);
    &::before {
      content: '';
    }
  }
  $pos: -50px;
  $pos-lg: -21px;
  .slick-prev {
    left: $pos;
    @media only screen and (max-width: 1120px) {
      left: 0;
    }
  }
  .slick-next {
    right: $pos;
    @media only screen and (max-width: 1120px) {
      right: 0;
    }
  }
}
.portfolio__link {
  max-width: 400px;
  margin: 70px auto 0;
  @include mq(md) {
    margin-top: 30px;
  }
}

.portfolio-detail {
  @extend %basic-layout;
}
