.blog-article__detail header {
  h2 {
    margin: 0;
    border-bottom: 1px solid #bfbfbf;
    padding-bottom: 15px;
    color: $key-color-01;
    line-height: 1.3;
    text-align: left;
    font-size: 24px;
  }
  aside {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0;
    // padding-bottom: 25px; ### Replace Other Style ###
    > p {
      @extend %roboto;
      margin-left: 5px;
      font-size: 14px;
    }
    > div {
      margin: 0 5px 0 auto;
      background-color: $text_color_01;
      font-size: 11px;
      a {
        display: block;
        padding: 4px 10px;
        color: $text_color_02;
      }
    }
    > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li {
      a {
        display: block;
        padding: 0 5px;
      }
    }
  }
}

.blog-article__body {
  @import '../../extend/editor';
  margin: 25px 0 0;
  line-height: 1.8;
}

.blog-article__detail + .pagination {
  border-top: 1px solid #bfbfbf;
  padding-top: map-get($spacers-pc, 'component');
  @include mq {
    padding-top: map-get($spacers-sp, 'component');
  }
}