.l-header {
  position: absolute;
  z-index: $z-header;
  inset: 0 0 auto;
  height: $header-height-pc;
  padding: 5px 0;
  @include mq(md) {
    height: $header-height-sp;
  }

  &.--fixed {
    position: fixed;
    background-color: #fff;
    transform: translateY(-100%);
    transition: .2s ease-in-out;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
    &.--active {
      transform: translateY(0);

    }
  }

  &.--static {
    body.home & {
      @include mq-min(md) {
        margin-top: 80px;
        .l-header__logo {
          height: 150%;
        }
      }
      @include mq(md) {
        margin-top: 30/750*100vw;
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    gap: 40px;
    max-width: calc(1200px + calc((100% - 1200px) * 0.6));
    margin: 0px auto;
    padding: 0 50px;
    height: 100%;
    @include mq(md) {
      padding: 0 30/750*100vw;
    }
  }
  &__logo {
    margin: 0;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left center;
    }
  }
  &__navi {
    @include mq(lg) {
      display: none;
    }
    margin-left: auto;
  }
  &__contact {
    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 35px;
      border-radius: 30px;
      font-size: 1.4rem;
      background: $button-color-01;
      border: 1px solid $button-color-01;
      color: $text_color_02;
      &:hover {
        background: $key_color_02;
        border: 1px solid currentColor;
        color: $button-color-01;
      }
    }

    @include mq(lg) {
      display: none;
    }

  }
}

.l-global-navi {
  display: flex;
  align-items: center;
  gap: 40px;
  position: relative;
  &__line {
    background-color: $key_color_01;
    height: 4px;
    display: block;
    position: absolute;
    width: 50px;
    left: 0;
    bottom: -15px;
  }
}

