// 二段組みブロック
.separate-column__01,
.separate-column__02,
.separate-column__03 {
  .separate-column__container {
    display: flex;
    gap: 5%;
    @include mq(md) {
      flex-direction: column;
      gap: map-get($spacers-sp, 'element');
    }
  }
  &.__default {
    .separate-column__container {
      @include mq-min(md) {
        flex-direction: row;
      }
      & > *:first-child {
        margin-left: 0;
      }
      & > *:last-child {
        margin-right: 0;
      }
    }
  }
  &.__reverse {
    .separate-column__container {
      @include mq-min(md) {
        flex-direction: row-reverse;
      }
      & > *:first-child {
        margin-right: 0;
      }
      & > *:last-child {
        margin-left: 0;
      }
    }
  }
  .__figure {
    > img {
      display: block;
      width: 100%;
      max-width: none;
    }
  }
  @include mq(md) {
    .separate-column__container {
      // display: block;
      // > * {
      //   margin: 0 0 20px;
      //   &:last-child {
      //     margin-bottom: 0;
      //   }
      // }
    }
  }
}
.separate-column__01 {
  @extend %margin-component;
  .__figure {
    flex: 0 1 auto;
    display: block;
    width: 49%;
  }
  .__content {
    flex: 0 1 auto;
    width: 46%;
  }
  h2 {
    @extend %margin-element;
    @extend .heading__level-3;
    padding: 0;
    text-align: left;
  }
  p {
    @extend %margin-element;
    @extend .paragraph;
  }
  @include mq(md) {
    .__figure,
    .__content {
      width: auto;
    }
  }
  .separate-column__link {
    @extend %margin-element;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    max-width: 400px;
  }
}
.separate-column__02 {
  @extend %basic-layout;
}

.separate-column__03 {
  .separate-column__container {
    @include mq-min(md) {
      gap: 4%;
    }
    @include mq {
      gap: 0;
    }
  }
  .__column {
    @include mq-min(md) {
      width: 48%;
    }
  }
  table {
    @extend .table-basic;
  }
}
