.company-outline {
  .separate-column__03 {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
  &__introduction {
    margin: 25px 0 0;
    p {
      @extend .paragraph__x-small;
      margin: 25px 0 0;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .googlemap {
    @extend %margin-component;
  }
}
