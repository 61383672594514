.history {
  position: relative;
  width: 600px;
  margin: 0 auto;
  margin-top: 50px;
  @include mq(md) {
    width: 95%;
    margin-top: 30px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 0;
    left: 120px;
    height: auto;
    width: 2px;
    background-color: #bfbfbf;
    z-index: 0;
    @include mq(md) {
      top: 8px;
    }
  }
  li {
    display: block;
    margin: 40px 0 0;
    padding: 0;
    @include mq(md) {
      margin-top: 20px;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      dt {
        &::after {
          display: block;
          content: '';
        }
      }
    }
  }
  dl {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    z-index: 1;
  }
  dt {
    position: relative;
    flex: 0 0 122px;
    letter-spacing: 2px;
    vertical-align: top;
    line-height: 1.7;
    font-family: $font_family_03;
    font-size: 16px;
    font-weight: bold;
    font-style: oblique;
    &::after {
      position: absolute;
      top: 6px;
      right: 0;
      bottom: 0;
      display: none;
      width: 2px;
      height: auto;
      margin: auto;
      background-color: #fff;
    }
    @include mq(sm) {
      font-size: 13px;
    }
  }
  dd {
    position: relative;
    flex: 0 1 auto;
    padding: 0 0 0 30px;
    line-height: 1.7;
    font-size: 16px;
    @include mq(sm) {
      font-size: 13px;
    }
    &::before {
      content: '';
      position: absolute;
      background-color: $key_color_01;
      top: 4px;
      left: -7px;
      width: 12px;
      height: 12px;
      border-radius: 30px;
      @include mq(md) {
        top: 6px;
      }
    }
  }
}
