.c-message {

  &.--no-post {
    text-align: center;
  }
}

.c-concept-message {
  @extend %margin-component;
  text-align: center;
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
  p {
    margin: 2em 0;
    font-size: 1.8rem;
    line-height: 2;
    @include mq(md) {
      font-size: clamp(1.4rem, #{32/750*100vw}, 1.6rem);
    }
  }
}