.ornament-figure {
  @extend %grid-col-3a;
  figure {
    margin: 0;
    img {
      display: block;
      width: 100%;
    }

    &:nth-child(2) {
      @include mq(sm) {
        display: none;
      }
    }
    &:nth-child(3) {
      @include mq(md) {
        display: none;
      }
    }
  }
}
