.tabs {
  & > .tabs__navigation {
    margin-bottom: 0;
  }
  &__navigation {
    position: relative;
    margin: 0 0 60px;
    @include mq {
      margin-bottom: 40px;
    }
    &::before {
      position: absolute;
      top: 18px;
      display: none;
      @include rv_vw(70);
      border-top: 10px solid $key_color_01;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      z-index: 0;
      content: '';
      @include mq {
        display: block;
      }
    }
    > ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 480px;
      margin: 0 auto 40px;
      @include mq {
        display: none;
      }
      > li {
        position: relative;
        border-bottom: 3px solid $key_color_01;
        padding: 0 3px;
        line-height: 2.5;
        font-size: 16px;
        outline: none;
        &::before {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -7px;
          border: 7px solid transparent;
          border-top: 0px solid $key_color_01;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;
        }
        &.ui-tabs-active,
        &.current {
          &::before {
            border-top: 10px solid $key_color_01;
          }
        }
        &:hover {
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;
          &::before {
            border-top: 10px solid $key_color_01;
          }
        }
        > a {
          display: block;
          width: 100%;
          height: 100%;
          outline: none;
        }
      }
    }
    > select {
      position: relative;
      display: none;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      height: 45px;
      border: 1px solid $key_color_01;
      border-radius: 50px;
      @include pl_vw(80);
      background-color: rgba(0, 0, 0, 0);
      color: $key_color_01;
      font-size: 16px;
      z-index: 10;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      @include mq {
        display: flex;
      }
    }
  }
  &__content {
    margin-top: 60px;
    @include mq {
      margin-top: 40px;
    }
    h2 {
      @extend .heading__level-2__l;
      margin: 0;
    }
    .__content {
      margin: 60px 0 0;
      @include mq {
        margin-top: 40px;
      }
    }
  }
}
