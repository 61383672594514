.blog-article__nav {
  margin: 30px 0 0;
  @include mq {
    margin-top: 20px;
  }
  &:first-child {
    margin-top: 0;
  }
  h2 {
    @extend %aside-heading;
    & + ul {
      margin-top: 15px;
    }
  }
  ul {
    padding: 0 15px;
    margin: 20px 0 0;
  }
  li {
    font-size: 12px;
    line-height: 1.5;
    + li {
      margin-top: 8px;
    }
    a,
    strong {
      @extend %aside-link-list__item;
    }
    strong {
      color: $key_color_01;
      &:hover {
        text-decoration: none;
      }
    }
    ins {
      @extend %aside-link-list__new;
    }
  }

}
