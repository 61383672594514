.c-mainvisual {
  position: relative;
  // height: 60.21428vw;
  // min-height: 100vh;
  // min-height: -webkit-fill-available;
  min-height: calc(100vh + #{$section-clip-height});
  @include mq(md) {
    min-height: calc(100vh + #{$section-clip-height-sp});
    min-height: calc(100svh + #{$section-clip-height-sp});
    // min-height: 100dvh;
  }
  letter-spacing: 1px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  + * {
    margin-top: -$section-clip-height;
    @include mq(md) {
      margin-top: -$section-clip-height-sp;
    }
  }



  padding-bottom: $section-clip-height;
  clip-path: polygon(
      calc((100vw - #{$section-clip-width}) / 2) 0%,
      calc(100% + (#{$section-clip-width} - 100vw) / 2) 0%,
      calc(100% + (#{$section-clip-width} - 100vw) / 2) 100%,
      50% calc(100% - #{$section-clip-height}),
      calc((100vw - #{$section-clip-width}) / 2) 100%
    );
  @include mq(md) {
    padding-bottom: $section-clip-height-sp;
    clip-path: polygon(
        calc((100vw - #{$section-clip-width-sp}) / 2) 0%,
        calc(100% + (#{$section-clip-width-sp} - 100vw) / 2) 0%,
        calc(100% + (#{$section-clip-width-sp} - 100vw) / 2) 100%,
        50% calc(100% - #{$section-clip-height-sp}),
        calc((100vw - #{$section-clip-width-sp}) / 2) 100%
      );
    + .section {
      margin-top: -$section-clip-height-sp;
    }
  }

  &__bg {
    pointer-events: none;
    position: absolute;
    inset: 0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .slick-list,.slick-track {
      height: 100%;
    }
  }


  &__copy {
    // @include fz_vw(47.81);
    font-size: 42px;

    .ttl {
      letter-spacing: 5px;
      font-style: normal;
      position: relative;
      display: table;
      margin: 0 auto;
      font-weight: bold;
      @include mq(lg) {
        font-size: 37px;
      }
      @include mq(sm) {
        font-size: 30px;
      }
    }
    .sub-ttl {
      font-weight: normal;
      // background-color: $key_color_01;
      font-style: italic;
      font-size: 10px;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $text_color_04;
      display: table;
      width: auto;
      // @include pv_vw(6);
      // @include ph_vw(15);
      margin: 20px auto 0;
    }
  }

  .scroll-down {
    position: absolute;
    bottom: $section-clip-height * 1.5 ;
    left: 50%;
    transform: translateX(-50%);
    font-family: $font_family_03;
    // @include fz_vw(14);
    // @include mt_vw(190);
    font-size: 12px;
    font-style: italic;
    color: $text_color_02;
    letter-spacing: 1px;
    @include mq(md) {
      bottom: $section-clip-height-sp * 1.5 ;
      font-size: 12px;
      margin-top: 160px;
    }
  }
}



.scroll-down {
  display: block;
  line-height: 1;
  text-align: center;
  color: $text_color_02;
  font-style: oblique;
  font-size: 12px;
  position: relative;
  a {
    color: inherit;
    text-decoration: none;
    display: block;
    width: 40px;
    overflow: visible;
    .icon {
      position: relative;
      display: block;
      margin: 0 auto 10px;
      border: 1px solid;
      border-radius: 11px;
      width: 22px;
      height: 44px;
      &::before {
        position: absolute;
        top: 10px;
        left: 50%;
        content: '';
        width: 4px;
        height: 4px;
        margin-left: -2px;
        background-color: #fff;
        border-radius: 100%;
        animation: sdb 2s infinite;
      }
    }
    .text {
      overflow: visible;
      white-space: nowrap;
    }
  }
}
@keyframes sdb {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, -4px);
    transform: translate(0, -4px);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    -webkit-transform: translate(0, 18px);
    transform: translate(0, 18px);
  }
  100% {
    opacity: 0;
  }
}