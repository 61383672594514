.blog-article__list {
  a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 22px 0;
    transition: .3s ease;
    &:hover {
      background-color: #EEE;
      color: $key-color-01;
    }
  }
  .date {
    @extend %roboto;
    flex-shrink: 0;
    width: 100px;
    padding: 0 5px;
    text-align: center;
    font-size: 14px;
  }
  h2 {
    text-align: left;
    margin: 0;
    padding: 0 10px;
    font-size: 16px;
  }
  li {
    border-top: 1px solid #bfbfbf;
    line-height: 1.8;
    &:last-child {
      border-bottom: 1px solid #bfbfbf;
    }
  }
}
