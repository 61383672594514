@charset "UTF-8";

////////////////////////////////
//テンプレの種類を問わず、使用頻度の高い変数。
////////////////////////////////


$spacer: 1.5rem;
$spacers-pc: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  'section': $spacer * 8,
  'component': $spacer * 4,
  'element': $spacer * 2
);
$spacers-sp: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  'section': 160/750*100vw,
  'component':80/750*100vw,
  'element': 40/750*100vw
);


$z-header: 3;
$header-height-pc: 80px;
$header-height-sp: 64px;




//コンテンツ幅、ブレークポイント
$contents_max_width: 1000px;
$breakpoint_s: 450px;
$breakpoint_m: 768px;
$breakpoint_l: $contents_max_width;

$breakpoints: (
  'sm': 'screen and (max-width: 450px)',
  'md': 'screen and (max-width: 768px)',
  'lg': 'screen and (max-width: 1000px)'
) !default;
$breakpoints_min: (
  'sm': 'screen and (min-width: 450.5px)',
  'md': 'screen and (min-width: 768.5px)',
  'lg': 'screen and (min-width: 1000.5px)'
) !default;

//基本カラー
$key_color_01: #0068b7; //
$key_color_02: #ffffff; //
$key_color_03: #EE7640; //

$key_color_04: #005c12; //Green3
$key_color_05: #322400; //works,リボンの色
$key_color_06: #7d7d7d; //contact 仕切り線
$key_color_07: #bfbfbf; //company, 店舗情報アンダーライン
$key_color_08: #000000;
$key_color_09: #000000;
$key_color_10: #000000;

//透明度を含む
$alpha_color_01: rgba(0, 104, 183, 0.85); //second_header

//基本フォントカラー
$text_color_01: #000;
$text_color_02: #fff;
$text_color_03: #322400;
$text_color_04: #6f6f6f;
$text_color_05: #000000;

//header・footerのロゴを中心にするための調整パラメータ
//社名が一行の場合は0に。
$logo-padding-left: 40px;

//メインビジュアル、スライド画像
$top_image_01: 'top_image_01.jpg';
$top_image_02: 'top_image_02.jpg';
$top_image_03: 'top_image_03.jpg';
$top_image_04: 'top_image_04.jpg';

//3種のコース料理
$corse_image_01: 'corse_01.jpg';
$corse_image_02: 'corse_02.jpg';
$corse_image_03: 'corse_03.jpg';

//footer CSSに使う画像
$footer_image: 'footer.png';
$footer_image_sp: 'footer_sp.png';

//テクスチャー
$texture_01: 'texture_01.png';
$texture_02: 'texture_02.png';

//基本フォントファミリー
$font_family_base: Arial, '游ゴシック', YuGothic, 'Montserrat', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN',
  'メイリオ', Meiryo, sans-serif; //基本フォント
$font_family_base_min: '游明朝体', 'Yu Mincho', YuMincho, 'Montserrat', 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro',
  'ＭＳ Ｐ明朝', 'MS PMincho', serif; //明朝系基本フォント
$font_family_base_IE: Arial, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'Montserrat', 'メイリオ', Meiryo,
  sans-serif; //游ゴシック使わないバージョン（IE用）

//テンプレ固有フォント
$font_family_01: 'Dosis', sans-serif;
$font_family_02: 'Montserrat', sans-serif;
$font_family_03: 'Roboto', sans-serif;
$font_family_04: '';
$font_family_05: '';

