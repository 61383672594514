.pagination {
  @extend %margin-component;
  display: flex;
  justify-content: center;
  a,
  span {
    display: block;
    border-radius: 50px;
    background-color: $key_color_02;
    color: $text_color_01;
    line-height: 33px;
    text-align: center;
    font-size: 15px;
    font-family: $font_family_03;
  }
  a {
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: $key_color_01;
      color: $text_color_02;
      transition: 0.3s ease-in-out;
    }
  }
  .previouspostslink,
  .nextpostslink {
    padding: 0 7px;
    font-family: $font_family_base;
    &.disabled {
      visibility: hidden;
      // pointer-events:none;
      // opacity:.5;
    }
    &[href=""] {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .extend {
    margin: 0 7px;
  }
  .previouspostslink {
    margin-right: 7px;
    &::before {
      margin-right: 0.5em;
      content: '<';
    }
  }
  .nextpostslink {
    margin-left: 7px;
    &::after {
      margin-left: 0.5em;
      content: '>';
    }
  }
  .page,
  .current {
    @extend %roboto;
    flex: 0 1 33px;
    width: 33px;
    height: 33px;
    margin: 0 2px;
    @include mq(sm) {
      font-size: 14px;
    }
  }
  .current {
    background-color: $key_color_01;
    color: $text_color_02;
  }
  .page,
  .current,
  .extend {
    @include mq {
      display: none;
    }
  }
}
