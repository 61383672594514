.blog-container {
  display: flex;
  @include mq {
    display: block;
  }
  .__main-column {
    width: percentage(720/1000);
    flex-shrink: 1;
    @include mq {
      width: 100%;
    }
  }
  .__sub-column {
    width: 255px;
    margin-left: 45px;
    @include mq {
      width: 100%;
      margin: 40px 0 0;
    }
    footer {
      margin: 50px 0 0 10px;
      padding: 0;
      background: transparent;
      @include mq {
        margin-top: 30px;
      }
      p {
        margin: 0;
        padding: 0;
      }
      a {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
      }
      i {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        margin-right: 5px;
        border-radius: 50px;
        background-color: $text_color_01;
        color: $key-color-02;
        font-size: 10px;
      }
    }
  }
}
